.game-content {
  display: block;
}

.game-content.shop-page {
  height: 100vh;
}

.game-content .footer {
  position: fixed;
  bottom: 0;
  align-items: flex-end;
  justify-content: center;
}

.game-content .footer h3 {
  font-weight: 400;
  font-size: 26px;
  text-align: center;
  color: #5e4205;
  line-height: 30px;
}

.draggable-wrapper-main {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 99999;
}

.machine-in-game {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -24%);
}

.react-draggable-dragged,
.soap-wrapper,
.cloth-wrapper {
  position: absolute;
  bottom: 0;
  width: 100px;
  height: 100px;
  z-index: 999;
}

.soap-wrapper-bg,
.cloth-wrapper-bg {
  background-image: url(/public/images/Ellipse.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: absolute;
  bottom: 8vh;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  padding: 20px;
  z-index: 99999;
}

.soap-wrapper-bg {
  left: auto;
  right: 30px;
}
.cloth-wrapper-bg {
  left: 30px;
  right: auto;
}

.cloth-wrapper {
  left: 20px;
  right: auto;
}

.cloth-wrapper-bg.animate::after {
  position: absolute;
  content: "";
  background-image: url(/public/images/arrowDown.png);
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: -50px;
  left: 50%;
  transform: translate(-64%, 0);
  -webkit-animation: arrowAnimate 1500ms ease infinite;
  animation: arrowAnimate 1500ms ease infinite;
}

.soap-wrapper-bg.animate::after {
  position: absolute;
  content: "";
  background-image: url(/public/images/arrowDown.png);
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: -50px;
  left: 50%;
  transform: translate(-64%, 0);
  -webkit-animation: arrowAnimate 1500ms ease infinite;
  animation: arrowAnimate 1500ms ease infinite;
}

@-webkit-keyframes arrowAnimate {
  0% {
    transform: translate(-64%, 0);
  }
  50% {
    transform: translate(-64%, -30%);
  }
  100% {
    transform: translate(-64%, 0);
  }
}

@keyframes arrowAnimate {
  0% {
    transform: translate(-64%, 0);
  }
  50% {
    transform: translate(-64%, -30%);
  }
  100% {
    transform: translate(-64%, 0);
  }
}

.soap-wrapper {
  left: auto;
  right: 20px;
}

.react-draggable-dragged > img,
.cloth-wrapper > img,
.soap-wrapper > img {
  width: 100%;
  height: 80px;
  -o-object-fit: contain;
  object-fit: contain;
}

.instruction-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background: rgb(2 20 56 / 83%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.instruction-screen img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.instruction-screen.start-btn img {
  width: 200px;
  height: 200px;
  cursor: pointer;
  transition: all 300ms;
}

.instruction-screen.start-btn img:active {
  transform: scale(0.9);
}

.game-final-image {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.game-content.timeout-page .game-final-image {
  height: 100vh;
}

.shop-page .game-final-image,
.waiting-page .game-final-image {
  width: 100%;
  height: 95vh;
  -o-object-fit: fill;
  object-fit: fill;
  margin-bottom: -15vh;
}

.game-content .footer,
.landing-content.game-content.shop-page .footer,
.landing-content.game-content.waiting-page .footer {
  height: 20vh;
}

.game-content.game-machine-wrapper .footer {
  position: absolute;
}

.timer-wrapper.game-time-wrapper {
  padding: 1.8rem 44px 2.8rem;
  right: 20px;
  transform: scale(0.8) translateY(-18px);
  width: 74px;
}

.game-time-wrapper h6 {
  font-size: 72px;
  font-weight: 400;
  text-align: center;
  line-height: 67px;
}

.game-time-wrapper h5 {
  font-weight: 400;
  font-size: 36px;
  text-align: center;
  line-height: 30px;
}

.game-content.timeout-page {
  background: #021438;
}

.game-machine-wrapper {
  background-image: url(/public/images/mobMachineBg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #3b4b7b;
}

.animate-cta {
  -webkit-animation: animateCtaBtn 1000ms ease-in-out infinite;
  animation: animateCtaBtn 1000ms ease-in-out infinite;
}

@-webkit-keyframes animateCtaBtn {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes animateCtaBtn {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(0%);
  }
}

.shop-now-footer {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 1.5vh;
}

.shop-now-text {
  position: absolute;
  left: -80%;
}
